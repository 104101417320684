<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getProdsNow()">
          <el-row>
            <!-- <el-col :md="8">
              <el-form-item label="委托单号：">
                <el-input size="small" v-model.trim="searchForm.requ_no" clearable placeholder="请填写委托单号"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :md="6">
              <el-form-item label="产品编号:">
                <el-input size="small" v-model.trim="searchForm.prod_no" clearable placeholder="请填写产品编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6">
              <el-form-item label="产品名称:">
                <el-input size="small" v-model.trim="searchForm.prod_name" clearable placeholder="请填写产品名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="10">
              <el-form-item label="单据状态:">
                <el-select v-model="searchForm.status" filterable placeholder="请选择单据状态" clearable size="small">
                  <el-option
                      v-for="item in statusList"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
               <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getProdsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
              </el-form-item>
            </el-col>
          <!-- </el-row>
          <el-row> -->
            <!-- <el-col :md="12"> -->
              <!-- <el-form-item label="产品类型：">
                <el-select v-model="searchForm.prod_type" placeholder="请选择产品类型" size="small" clearable>
                  <el-option
                        v-for="item in prodType"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id">
                    </el-option>
                </el-select>
              </el-form-item> -->
            <!-- </el-col> -->
          </el-row>
          <el-row>
            <el-col :md="14">
              <!-- <el-form-item label="实际交样时间：">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item> -->
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <!-- <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button> -->
        <el-button type="danger" size="small" v-if="specialDataFlag" :disabled='!btn.delete' @click="doDelete()"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table ref="multiTable" v-loading="loadingFlag" class="vg_cursor" :data="tableData" @selection-change="handleSelectionChange" @select="selectRows" @select-all="selectRows" @row-dblclick="dbClickJp" border>
            <el-table-column type="selection" v-if="specialDataFlag" width="48"  align="center"/>
            <!-- <el-table-column label="委托单号" prop="requ_no"/> -->
            <el-table-column label="产品编号" prop="prod_no"/>
            <el-table-column label="客户货号" prop="prod_cust_no">
              <template slot-scope="scope">
                <span v-if="scope.row.prod_cust_no">
                  {{ scope.row.prod_cust_no}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="产品名称" prop="prod_name" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.prod_name">
                  {{ scope.row.prod_name}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="开版师" prop="prod_stff_name" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.prod_stff_name">
                  {{ scope.row.prod_stff_name}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="产品类型" prop="prod_type" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.prod_type">
                  {{ scope.row.prod_type | formatProdType}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="产品间报价" prop="prod_price">
              <template slot-scope="scope">
                <span v-if="scope.row.prod_price">
                  {{ scope.row.prod_price | formaUnitPr}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="采购部核价" prop="prod_verify_price">
              <template slot-scope="scope">
                <span v-if="scope.row.prod_verify_price">
                  {{ scope.row.prod_verify_price | formaUnitP}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="打样数量" prop="prod_num" >
              <template slot-scope="scope">
                <span v-if="scope.row.prod_num">
                  {{ scope.row.prod_num}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="实际交样时间" prop="prod_deil_time" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.prod_deil_time">
                  {{ scope.row.prod_deil_time | formatDate1}}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column> -->
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip/>
            <el-table-column label="单据状态" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{helper.getStatusName(scope.row.status).name}}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {prodAPI} from "@api/modules/prod"
import pubPagination from "@/components/common/pubPagination";
import helper from "@assets/js/helper.js"
export default {
name: "prodList",
  components: {
    pubPagination,
  },
  data() {
    return {
      searchForm: {
        status:null,
        prod_name: '',
        prod_no: '',
      },
      currentPage: 1,
      totalPage:0,
      tableData: [],
      tableDataList:[],
      multiSelection: [],
      btn:{},
      prodType:[
        {id:1,label:'新款'},
        {id:2,label:'老款'},
        {id:3,label:'修改款'},
      ],
      loadingFlag:true,
      specialDataFlag:false,
      statusList:[{
        id:0,
        label:'草拟'
      },{
        id:1,
        label:'在批'
      },{
        id:2,
        label:'生效'
      },]
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/prod_edit'){
        this.initData()
      }
    },
  },
  filters:{
    // 产品类型转换
    formatProdType(row){
      if(row === 1){
        return '新款'
      }else if(row === 2){
        return '老款'
      }else if(row === 3){
        return '修改款'
      }
    },
    //交样时间
    formatDate1(row) {
      return helper.toStringDate(row)
    },
    formaUnitP(row){
      return helper.haveFour(row)
    },
    // 物料单位转换
    formaUnitPr(row){
      return helper.haveFour(row)
    },
  },
  methods: {
    initData(){
      this.loadingFlag = true
      this.getProds();
      if(this.$cookies.get('userInfo').acct_id === 1){
        this.specialDataFlag = true
      }
    },
    // 获取产品信息
    getProds() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      get(prodAPI.getProds,
          {
            requ_no:this.searchForm.requ_no,
            prod_no: this.searchForm.prod_no,
            prod_name: this.searchForm.prod_name,
            prod_type:this.searchForm.prod_type,
            start_time: timeNewVal.startTime,
            end_time: timeNewVal.endTime,
            status: this.searchForm.status,
            page_no: this.currentPage,
          })
          .then(res => {
            if(res.data.code === 0){
              this.tableData = res.data.data.list;
              this.totalPage = res.data.data.total
              this.btn = res.data.data.btn;
              setTimeout(() => {
                this.loadingFlag = false
              }, 500);
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        prod_name: '',
        status:null,
        prod_no: '',
      };
      this.loadingFlag = true
      this.currentPage = 1
      this.initData()
      this.$refs.pubPagination.currentPage = 1
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time)
    },
    // 查询方法
    getProdsNow(){
      this.loadingFlag = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 分页查询
    changePageSearch(val){
      this.currentPage = val
      this.initData()
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    selectRows(selection){
      this.btn.delete = this.helper.isDelete(selection)
    },
    // 状态查询
    getStatusVal(val){
      this.searchForm.status = val
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        let temp = false
        for(let i=0;i<this.multiSelection.length;i++){
          if(this.multiSelection[i].status === 2){
            temp = true;
            break;
          }
        }
        if(temp){
          this.$message({type:'warning',message:'已生效的数据不可删除！'})
          this.$refs.multiTable.clearSelection();
        }else{
          this.mBox()
        }
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.prod_id)
      })
      post(prodAPI.destroyProdByIds,{'prod_id_list':ids})
          .then(res=>{
            if(res.data.code === 0){
              let mg = res.data.msg;
              let tp = 'success';
              this.$message({message:mg,type:tp});
              if(ids.length === this.tableData.length){
                if(this.currentPage > 1){
                  this.currentPage = this.currentPage -1
                  this.$refs.pubPagination.currentPage = this.currentPage
                }
              }
              this.initData()
            } else {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            }
          })
          .catch(res=>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message:mg,type:tp});
          })
    },
    // 双击row跳转到详情编辑
    dbClickJp(row){
      const permId = this.$route.query.perm_id
      this.jump('/prod_edit',{perm_id:permId,form_id:row.prod_id})
    }
  }
}
</script>

<style scoped lang="scss">
.vd_button_group{
  width: 100vw;
  position: relative;
}
.vd_txta {
  position: absolute;
  left: 35%;
}
::v-deep .vd_tenpd{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.vd_img{
  margin-top: 5px;
  width: 40px;
}
.vd_notpass{
  color: rgb(187, 187, 187);
}
.vd_pass{
  color: #188dff;
}
.vd_complate{
  color: #36ab60;
}
.vd_disable{
  color:#d81e06;
}
.vd_wei{
  color: #ff8511;
}
</style>